import axios from 'axios';
import {store} from '../store';
import { API_URL } from '../constants';
import {getSessionItem, setSessionItem, removeSessionItem, _dispatch} from '../utilities';
import {ToastsStore} from 'react-toasts';
export {_dispatch};
export const SAVE_AUTH = 'SAVE_AUTH';
export const UPDATE_SESSION = 'UPDATE_SESSION';

export function postTitleApi(title, todoList, doRemember){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {
		  sessionId: user.session.id,
		  title: title,
		  todoList: todoList,
		  doRemember: doRemember
		},
      	url: `${API_URL}/session/add-title`
	});
}

export function createClientVideo(payload){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: payload,
      	url: `${API_URL}/session/client-video`
	}).then(response => {
		setSessionItem('user', {...user, session: response.data.body.session});
		const {dispatch} = store;
		dispatch({
			type: UPDATE_SESSION,
			payload: response.data.body.session
		});
	});
}

export function finalizeClientVideo(){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {sessionId: user.session.id},
      	url: `${API_URL}/session/client-video/finalize`
	}).then(response => {
		setSessionItem('user', {...user, session: response.data.body.session});
		const {dispatch} = store;
		dispatch({
			type: UPDATE_SESSION,
			payload: response.data.body.session
		});
	});
}

export function createCoachVideo(payload){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {...payload, sessionId: user.session.id},
      	url: `${API_URL}/session/coach-video`
	}).then(response => {
		setSessionItem('user', {...user, session: response.data.body.session});
		const {dispatch} = store;
		dispatch({
			type: UPDATE_SESSION,
			payload: response.data.body.session
		});
	});
}

export function finalizeCoachVideo(){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {sessionId: user.session.id},
      	url: `${API_URL}/session/coach-video/finalize`
	}).then(response => {
		setSessionItem('user', {...user, session: response.data.body.session});
		const {dispatch} = store;
		dispatch({
			type: UPDATE_SESSION,
			payload: response.data.body.session
		});
	});
}


export function createExpertVideo(payload, sessionId){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {...payload, sessionId},
      	url: `${API_URL}/session/expert-user-video`
	});
}

export function finalizeExpertVideo(sessionId){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {sessionId},
      	url: `${API_URL}/session/expert-user-video/finalize`
	});
}


export function getSessions(pageNumber, limit, params = {}){
	const user = getSessionItem('user');
	return axios({
      	method: 'GET',
      	headers: {Authorization: `Bearer ${user.token}`},
		params: {...params, limit, skip: (limit * (pageNumber - 1))},
      	url: `${API_URL}/session`
	});
}

export function deleteSession(id){
	const user = getSessionItem('user');
	return axios({
      	method: 'DELETE',
      	headers: {Authorization: `Bearer ${user.token}`},
      	url: `${API_URL}/session/${id}`
	});
}

export function fetchSession(id){
	const user = getSessionItem('user');
	return axios({
      	method: 'GET',
      	headers: {Authorization: `Bearer ${user.token}`},
      	url: `${API_URL}/session/${id}`
	});
}

export function sessionUpdate(payload){
	const user = getSessionItem('user');
	return axios({
      	method: 'PUT',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: payload,
      	url: `${API_URL}/session/update`
	});
}

export function sendExpertVideoRequest(sessionId){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {sessionId},
      	url: `${API_URL}/user/request-expert-user`
	});
}

export function updateVideoStatus(payload){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: payload,
      	url: `${API_URL}/session/client-video/pdf-finalize`
	});
}

export function markToDoComplete(id, sessionId){
	const user = getSessionItem('user');
	return axios({
      	method: 'POST',
      	headers: {Authorization: `Bearer ${user.token}`},
      	data: {id, sessionId},
      	url: `${API_URL}/mark-todo-complete`
	});
}