import {
  SAVE_AUTH,
  _dispatch,
  TOTAL_SESSION,
  TOTAL_STATS
} from '../actions/user';

import {
  UPDATE_SESSION
} from '../actions/session';

import { getSessionItem } from '../utilities';

const user = getSessionItem('user');
const INITIAL_STATE = { auth: false, stages: {}, totalSession: 0, session: (user && typeof user.session !== 'undefined' && user.session !== null ? user.session : { stage: 1 }), status: null }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case SAVE_AUTH:
      return _dispatch({ ...state, auth: action.payload, session: action.session }, true, 'auth');
      break;

    case UPDATE_SESSION:
      return _dispatch({ ...state, session: action.payload }, true, 'session_update');
      break;

    case 'COMPLETE_SESSION':
      return _dispatch({ ...state, session: { stage: 1 } }, true, 'session_update');
      break;

    case TOTAL_SESSION:
      return _dispatch({ ...state, totalSession: action.payload })
    case TOTAL_STATS:
      return _dispatch({ ...state, totalStats: action.payload })

    default:
      return state;
  }
}