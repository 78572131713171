import React, { Component, Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes';
import ContentLoader from './components/ContentLoader';
import RenderRemoteComponent from './components/RenderRemoteComponent';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { getSessionItem } from './utilities';

class App extends Component {

  render() {
    return (
      <>
        <BrowserRouter>
          <Suspense fallback={<ContentLoader />}>
            <Switch>
              <Route {...ROUTES.HOME} />
              <Route {...ROUTES.SIGNUP_PAYMENT_VERIFICATION} />
              <Route {...ROUTES.LOGOUT} />
              <Route {...ROUTES.DASHBOARD} />
              <Route {...ROUTES.NOTFOUND} />
              <Route {...ROUTES.STATICPAGE} />
              <Route {...ROUTES.ACTIVATIONLINK} />
              <Route {...ROUTES.FAQ_PAGE} />
              <Route {...ROUTES.RESETPASSWORD} />
              <Route component={ROUTES.NOTFOUND.component} />
            </Switch>
          </Suspense>
        </BrowserRouter>
        <RenderRemoteComponent />
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
      </>
    );
  }
}

export default App;